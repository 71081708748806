<template>
  <!--begin::Content-->
  <div class="flex-lg-row-fluid ms-lg-7 ms-xl-10">
    <!--begin::Messenger-->
    <div class="card" id="kt_chat_messenger">
      <!--begin::Card header-->
      <div class="card-header" id="kt_chat_messenger_header">
         <!--begin::Title-->
         <div class="card-title">
            <!--begin::User-->
            <div class="d-flex justify-content-center flex-column me-3">
              <a
                href="#"
                class="
                  fs-4
                  fw-bolder
                  text-gray-900 text-hover-primary
                  me-1
                  lh-1
                "
                >Personel Konuşma Geçmişleri</a
              >
            </div>
            <!--end::User-->
          </div>
          <!--end::Title-->

      </div>

      <div class="card-body" id="kt_chat_messenger_body">
          <!--begin::Messages-->
          <div
            class="scroll-y me-n5 pe-5 h-650px"
            ref="messagesRef"
            data-kt-element="messages"
            data-kt-scroll="true"
            data-kt-scroll-activate="{default: false, lg: true}"
            data-kt-scroll-max-height="auto"
            data-kt-scroll-dependencies="#kt_header, #kt_toolbar, #kt_footer, #kt_chat_messenger_header, #kt_chat_messenger_footer"
            data-kt-scroll-wrappers="#kt_content, #kt_chat_messenger_body"
            data-kt-scroll-offset="-2px"
          >
          Lütfen incelemek istediğiniz konuşma geçmişini seçiniz...
        </div>
        </div>
    </div>
  </div>
</template>
